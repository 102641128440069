const IconThree = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40.102"
        height="40.102"
        viewBox="0 0 40.102 40.102"
    >
        <g transform="translate(-492 -380)">
            <g transform="translate(492 380)">
                <path
                    d="M4.586,7.247A3.682,3.682,0,1,1,7.327,3.2"
                    transform="translate(16.383 14.341)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M7.4,0A14.679,14.679,0,0,0,4.7,23.509"
                    transform="translate(5.364 5.263)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M10.042,25.381a14.589,14.589,0,0,0,4.645-10.694A14.686,14.686,0,0,0,0,0"
                    transform="translate(20.051 3.342)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M2.49,15.456a9.173,9.173,0,1,1,13.367,0"
                    transform="translate(10.878 8.856)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M3.211,1.366.805,4.357a3.638,3.638,0,0,0,2.841,5.915h4.8a3.636,3.636,0,0,0,2.841-5.915L8.875,1.366A3.619,3.619,0,0,0,3.211,1.366Z"
                    transform="translate(13.999 26.471)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M0,0H40.1V40.1H0Z"
                    transform="translate(40.102 40.102) rotate(180)"
                    fill="none"
                    opacity="0"
                />
            </g>
        </g>
    </svg>
);

export default IconThree;
