const IconOne = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40.102"
        height="40.102"
        viewBox="0 0 40.102 40.102"
    >
        <g transform="translate(-172 -316)">
            <g transform="translate(172 316)">
                <path
                    d="M5.1,0,0,5.1"
                    transform="translate(10.894 20.819)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M0,0,5.1,5.1"
                    transform="translate(10.944 20.87)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M0,0H.017"
                    transform="translate(22.607 23.393)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M0,0H.017"
                    transform="translate(29.191 23.393)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M0,.033V0"
                    transform="translate(25.899 26.668)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M0,.033V0"
                    transform="translate(25.899 20.084)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M0,15.038c0,8.354,3.342,11.7,11.7,11.7H21.722c8.354,0,11.7-3.342,11.7-11.7V11.7c0-8.354-3.342-11.7-11.7-11.7H11.7C5.43,0,1.988,1.871.652,6.349"
                    transform="translate(3.342 10.025)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M3.392,0,3.375,1.688A1.672,1.672,0,0,1,1.7,3.342h-.05a1.671,1.671,0,0,0,.017,3.342H3.342"
                    transform="translate(18.346 3.342)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path d="M0,0H40.1V40.1H0Z" fill="none" opacity="0" />
            </g>
        </g>
    </svg>
);

export default IconOne;
