import React, { useState, useEffect } from "react";

const Header = (props) => {
    const [activeId, setActiveId] = useState(1);
    const values = [
        {
            id: 1,
            icon: props.homeIcon,
            text: "HOME",
        },
        {
            id: 2,
            icon: props.weAreIcon,
            text: "WE ARE",
        },
        {
            id: 3,
            icon: props.projectsIcon,
            text: "PROJECTS",
        },
        {
            id: 4,
            icon: props.contactIcon,
            text: "CONTACT",
        },
    ];

    const Mmosue = (e) => {
        const Header = document.querySelector("header");
        const UL = document.querySelector("header ul");
        const X = e.clientX;
        if (document.dir === "ltr") {
            Header.onmouseenter = () => {
                UL.style = `transform: translate3d(${X - 200}px , 0 , 0)`;
            };
            Header.onmouseleave = () => {
                UL.style = `transform: translate3d(0 , 0 , 0)`;
            };
        } else {
            Header.onmouseenter = () => {
                UL.style = `transform: translate3d(${
                    e.clientX - 200
                }px , 0 , 0)`;
            };
            Header.onmouseleave = () => {
                UL.style = `transform: translate3d(0 , 0 , 0)`;
            };
        }
    };
    window.addEventListener("mousemove", Mmosue);

    return (
        <header>
            <ul>
                {values.map((val) => (
                    <li
                        onClick={() => {
                            setActiveId(val.id);
                        }}
                        className={activeId === val.id ? "active" : " "}
                        key={val.id}
                    >
                        {val.icon}
                        <span>{val.text}</span>
                    </li>
                ))}
            </ul>
        </header>
    );
};
export default Header;
