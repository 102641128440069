import React from "react";
import "animate.css";
/* Include Components */
import Footer from "./Components/Footer/Footer";
import DivLogo from "./Components/Logo/Logo";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/main_01";
import WeAre from "./Components/WeAre/WeAre";

// import WeAre from "./Components/WeAre/main_01";
/* Include Custom Cursor */
// import CustomCursor from "./Components/CustomCursor/CustomCursor";

/* Include Style Fills */
import "./main.css";
/* Include Images Footer */
import FooterLogo from "./Images/SVG/Footer_Logo.svg";
/* Include Images Header */
import HeaderLogo from "./Images/SVG/Header_Logo.svg";

const App = () => {
    return (
        <>
            <div className="container">
                <Footer logo_footer={FooterLogo} />
            </div>
            {/* <WeAre /> */}
            <Header
                homeIcon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M0,0H20"
                            transform="translate(2 22)"
                            fill="none"
                            stroke="red"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M0,19.47.05,7.44A2.015,2.015,0,0,1,.82,5.87l7-5.45a2.011,2.011,0,0,1,2.46,0l7,5.44a1.99,1.99,0,0,1,.77,1.58V19.47"
                            transform="translate(2.95 2.53)"
                            fill="none"
                            stroke="red"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M4.43,3,4.4,0H0"
                            transform="translate(14.57 4)"
                            fill="none"
                            stroke="red"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path d="M0,0H24V24H0Z" fill="none" opacity="0" />
                    </svg>
                }
                weAreIcon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M0,5C0,1,1,0,5,0H15c4,0,5,1,5,5v8c0,4-1,5-5,5H5c-4,0-5-1-5-5V9.4"
                            transform="translate(2 3)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M0,0H5"
                            transform="translate(14 8)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M0,0H4"
                            transform="translate(15 12)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M0,0H2"
                            transform="translate(17 16)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M3.62,1.81A1.81,1.81,0,1,1,1.81,0,1.81,1.81,0,0,1,3.62,1.81Z"
                            transform="translate(6.69 7.67)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M7,2.758A3.02,3.02,0,0,0,4.26.038a7.72,7.72,0,0,0-1.52,0A3.029,3.029,0,0,0,0,2.758"
                            transform="translate(5 13.573)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path d="M0,0H24V24H0Z" fill="none" opacity="0" />
                    </svg>
                }
                projectsIcon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M2,0,0,2,2,4"
                            transform="translate(6 10)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M0,0,2,2,0,4"
                            transform="translate(16 10)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M2,0,0,4.66"
                            transform="translate(11 9.67)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path d="M0,0H24V24H0Z" fill="none" opacity="0" />
                        <path
                            d="M2,4a10,10,0,1,0,8-4A9.865,9.865,0,0,0,5.97.85"
                            transform="translate(2 2)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                    </svg>
                }
                contactIcon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M4.969,7.415a2.137,2.137,0,0,1-2.93,0C.919,6.345-.371,4.625.1,2.6c.8-3.46,6.01-3.46,6.8,0A3.715,3.715,0,0,1,6.969,4"
                            transform="translate(1.971 2.005)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M.1,2.6c.8-3.46,6.04-3.46,6.84,0,.47,2.03-.82,3.75-1.95,4.82a2.149,2.149,0,0,1-2.94,0C.919,6.345-.371,4.625.1,2.6Z"
                            transform="translate(14.971 14.005)"
                            fill="none"
                            stroke="#474747"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M4.677,0h2.68a2,2,0,0,1,1.32,3.51L.687,10.5A2,2,0,0,0,2,14h2.68"
                            transform="translate(7.323 5)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M4.677,0h2.68a2,2,0,0,1,1.32,3.51L.687,10.5A2,2,0,0,0,2,14h2.68"
                            transform="translate(7.323 5)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M.636.5H.648"
                            transform="translate(4.85 5)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                        <path
                            d="M.636.5H.648"
                            transform="translate(17.85 17)"
                            fill="none"
                            stroke="#474747"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                        <path d="M0,0H24V24H0Z" fill="none" opacity="0" />
                    </svg>
                }
            />
            <DivLogo logo_header={HeaderLogo} />
            <Home />
            <WeAre />
            {/* <CustomCursor /> */}
        </>
    );
};
export default App;
