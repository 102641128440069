const Div_Logo = (props) => {
    document.onscroll = (e) => {
        const Div_Image = document.querySelector(".div-logo");
        const Image_Content = document.querySelector(".div-logo .image");
        const Image = document.querySelector(".div-logo .image  img");

        if (window.scrollY > 100 && window.innerWidth > 576) {
            Div_Image.style = `
            position:fixed;
            transition: all 1s ease;
            transform: rotate(0deg) ;
            top: -920px;
            left: 0px;
            border-left-color: transparent ;
            border-bottom-left-radius: 0;
            box-shadow: 0 0 20px  10px #81000082;
            z-index: 99999;
            background: #14141491;
            `;
            // Image.style = `right : 100px !important`;
            Image_Content.style = `
            left : 70% ;
            `;
            Image.style = `
            transform: rotate(0deg)
            `;
        } else {
            Div_Image.style = `
            transform: rotate(-28deg) ;
            transition: all 1s ease;
                top: -780px;
            left: -20px;
            background: #141414;
            border-left-color: #810000 ;
            border-bottom-left-radius: 15px;
            box-shadow: 0 0 20px  10px #81000082;
            `;
            Image.style = `
            transition: all 1s ease;
            transform: rotate(28deg)
            left : 70% ;
            `;
        }
    };
    return (
        <div className="div-logo">
            <div className="image animate__animated animate__slideInDown  animate__delay-0s">
                <img
                    className="div-logo_img"
                    src={props.logo_header}
                    alt="Header Logo"
                />
            </div>
        </div>
    );
};
export default Div_Logo;
