const Footer = (props) => (
    <footer className="container">
        <p>
            &copy; {new Date().getFullYear()} -{" "}
            <img src={props.logo_footer} alt="Footer Logo" /> All rights
            reserved
        </p>
    </footer>
);

export default Footer;
