const IconTwo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.184"
        height="36.175"
        viewBox="0 0 26.184 36.175"
    >
        <g transform="translate(-822.419 -1989.44)">
            <path
                d="M10.189,3.421A20.771,20.771,0,0,0,7.3,0S7.042,1.71,1.739,8.552C-3.238,15,3.963,19.91,4.818,20.474a.155.155,0,0,0,.171,0c.787-.5,6.893-4.413,7.218-10.417"
                transform="translate(835.343 2004.105)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                d="M15.057,9.877A16.76,16.76,0,0,0,11.978.3.9.9,0,0,0,10.438.64a44.044,44.044,0,0,1-7.7,14.539A11.879,11.879,0,0,0,8.215,33.994c3.25.855-.855-1.71-1.368-7.013C6.333,20.311,15.057,15.35,15.057,9.877Z"
                transform="translate(823.479 1990.447)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);
export default IconTwo;
