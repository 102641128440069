import React from "react";
import Lottie from "react-lottie";
import animationData from "../../LottieFile/lf20_yfsm7adp.json";
const Main_01_Lottie = () => {
    const defaultOption = {
        isClickToPauseDisabled: false,

        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div className="main-01_Lottie ">
            <Lottie options={defaultOption} isClickToPauseDisabled={true} />
        </div>
    );
};
export default Main_01_Lottie;
