import Main01Lottie from "./LottieFIle";
import Main01Text from "./Text";
import SVGPattern from "../SVGPattern/PatternSVG";

const Main_01 = () => {
    const content = {
        title: "AWDS Is ",
        titleSpan: ">",
    };
    return (
        <div className="main-01">
            <SVGPattern />
            <div className="container">
                <div className="main-01_content">
                    <Main01Text
                        title={content.title}
                        span={content.titleSpan}
                        description={content.description}
                    />
                    <Main01Lottie />
                    <h1 className="title-heading animate__animated animate__fadeInLeft">
                        Home
                    </h1>
                </div>
            </div>
        </div>
    );
};
export default Main_01;
