const Main_01_text = (props) => {
    const moveText = (e) => {
        const div_text = document.querySelector(".main-01_text");
        const text = document.querySelector(".main-01_text .text");

        if (window.scrollY < 50) {
            div_text.style = `
            transform:translate3d(0, ${window.scrollY}px, 0)
            `;
        }
        text.style = ``;
        if (window.scrollY < 200) {
            text.classList.remove("animate__fadeOutDown");

            text.style = `
            transition: all 1s ease !important;
            opacity: 1;
            transform: translate3d(0, 0, 0)  
            `;
        }
        if (window.scrollY > 100) {
            text.classList.add("animate__fadeOutDown");
        }
    };

    // text.style = `transform:translate3d(0, ${window.clientX}, 0)`;
    window.addEventListener("scroll", moveText);
    return (
        <div className="main-01_text ">
            <div className="text animate__animated">
                <h1>
                    {props.title}
                    <span>{props.span}</span>
                </h1>
                <p>
                    A team of developers && designers are able to convert your
                    ideas impossible to
                    <i>
                        {" <Site />"}
                        <span>_</span>{" "}
                    </i>
                    a realistic with very high quality in using the latest
                    technology and latest designs.<span>_ </span>
                    <site />
                </p>
            </div>
        </div>
    );
};
export default Main_01_text;
