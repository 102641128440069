import React, { useEffect } from "react";
import IconOne from "./SVG/IconOne";
import IconThree from "./SVG/IconThree";
import IconTwo from "./SVG/IconTwo";
const CardOne = (props) => {
    return (
        <div className="card_box">
            <div className="cards_card" id="card">
                <div className="face together">
                    <h4>{props.heading}</h4>
                    <div className="image">
                        <img src={props.img} alt={props.alt} width={100} />
                    </div>
                    <ul className="contact">
                        <li>
                            <IconOne />
                        </li>
                        <li>
                            <IconThree />
                        </li>
                        <li>
                            <IconTwo />
                        </li>
                    </ul>
                </div>
                <div className="back together">
                    <h5>{props.name}</h5>
                    <p>{props.description}</p>
                </div>
            </div>
        </div>
    );
};
export default CardOne;
