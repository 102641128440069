import Card from "./Card";
import SVGPattern from "./../SVGPattern/PatternSVG";
import PicOne from "./../../Images/PNG/1.png";
import PicTwo from "./../../Images/PNG/2.png";
import PicThree from "./../../Images/PNG/3.png";
import { useEffect } from "react";

const WeAre = () => {
    const CardData = [
        {
            name: "Alaa",
            description:
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, facilis nihil ea natus ex doloribus architecto maiores exercitationem, repellat repellendus, dolorem doloremque eum aspernatur blanditiis eligendi ipsam consequuntur quam autem!",
            heading: "UI / UX",
            img: PicOne,
            alt: "alt 1",
        },
        {
            name: "Abdullah",
            description:
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, facilis nihil ea natus ex doloribus architecto maiores exercitationem, repellat repellendus, dolorem doloremque eum aspernatur blanditiis eligendi ipsam consequuntur quam autem!",
            heading: "Full-stack",
            img: PicTwo,
            alt: "alt 2",
        },
        {
            name: "Osama",
            description:
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, facilis nihil ea natus ex doloribus architecto maiores exercitationem, repellat repellendus, dolorem doloremque eum aspernatur blanditiis eligendi ipsam consequuntur quam autem!",
            heading: "Backend",
            img: PicThree,
            alt: "alt 3",
        },
    ];
    return (
        <>
            <div id="we-are"></div>
            <div className="we-are">
                <SVGPattern />
                <div className="cards container">
                    {CardData.map((Person) => (
                        <Card
                            name={Person.name}
                            description={Person.description}
                            heading={Person.heading}
                            img={Person.img}
                            alt={Person.alt}
                        />
                    ))}
                    <h1 className="title-heading animate__animated animate__fadeInLeft">
                        We
                        <br />
                        Ara
                    </h1>
                </div>
            </div>
        </>
    );
};
export default WeAre;
